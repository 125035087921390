import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Routes, Route } from 'react-router-dom';
import Pesquisa from './pesquisa';
import Visualizacao from './visualizacao';
import Utils from '../../utils';
import {
    SttLoading,
    SttTranslateHook
} from '@stt-componentes/core';
import { useSignal, useSignals } from '@preact/signals-react/runtime';

const useStyles = makeStyles(theme => ({
    carregando: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
}));

const Aba = ({ ...other }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const gerandoPdf = useSignal(false);

    const imprimir = (idExame) => {
        gerandoPdf.value = true;
        Utils.imprimirFn({ id: idExame }, () =>  gerandoPdf.value = false);
    }

    const imprimirProtocolo = (exame) => {
        gerandoPdf.value = true;
        Utils.imprimirProtocoloFn(exame, () => gerandoPdf.value = false);
    }

    const imprimirTermo = (exame) => {
        gerandoPdf.value = true;
        Utils.imprimirTcleFn({ id: exame.id_paciente }, () => gerandoPdf.value = false);
    }

    return (
        <>
            <Routes>
                <Route path="/visualizar/:id" element={<Visualizacao imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirTermoFn={imprimirTermo} />} />
                <Route path="/visualizar" element={<Visualizacao imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirTermoFn={imprimirTermo} />} />
                <Route path="/" element={<Pesquisa {...other} imprimirFn={imprimir} imprimirProtocoloFn={imprimirProtocolo} imprimirTermoFn={imprimirTermo} />} />
            </Routes>
            <div className={classes.carregando}>
                <SttLoading
                    open={gerandoPdf.value}
                    text={strings.gerandoDocumento}
                />
            </div>
        </>
    );
}

export default Aba;