import React, { useEffect, useContext } from 'react';
import { Formik } from 'formik';
import axios from 'axios';
import HttpStatus from 'http-status-codes';
import { getHeaders } from '../../request';
import { validationDadosGerais } from './informacoes-gerais/validationSchema';
import {
    SttLoading,
    SttTranslateHook
} from '@stt-componentes/core';
import ModalDadosGerais from './informacoes-gerais';
import { MODALIDADE } from '../../common/Constants';
import { useSignal, useSignalEffect, useSignals } from '@preact/signals-react/runtime';
import usuario from '../../signals/usuario';
import alerta from '../../signals/alerta';
import Utils from '../../utils';

const initialValues = {
    equipamento: null,
    observacao: '',
    requisicao: '',
    dataTeste: new Date(),
    validarImagem: false,
    anexo: [{}]
};

const EnvioImagens = ({ open, resetFormulario, solicitacao = {}, handleCancelarEnvio, callbackFinalizarEnvio }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    useSignals();

    const schemaInformacoesGerais = validationDadosGerais(strings, usuario);

    const steps = [{ titulo: strings.dadosGerais }, { titulo: strings.anexos }];
    const stepAtual = useSignal(0);

    const exameEnviado = useSignal(null);

    const progresso = useSignal(false);
    const mensagemProgresso = useSignal(strings.mensagemEnviandoImagens);

    const gerarProtocolo = useSignal(false);
    const gerarTermo = useSignal(false);
    const paciente = useSignal(null);
    const ufSolicitante = useSignal(null);

    const equipamentos = useSignal([]);
    const erroEquipamento = useSignal(false);

    useEffect(() => {
        let instituicoes = [];
        if (usuario.value.habilitacao?.solicitacaoServico?.length) {
            instituicoes = usuario.value.habilitacao.solicitacaoServico.map(inst => inst.id);
        }

        if (instituicoes.length) {
            const stringInsts = instituicoes.join();
            axios.get(`${global.gConfig.url_base_utilitarios}/equipamento?modalidade=${MODALIDADE.SIGLA}&instituicao=${stringInsts}`, { headers: getHeaders() })
                .then((response) => {
                    if (response.data) {
                        equipamentos.value = response.data;
                    } else {
                        equipamentos.value = [];
                        erroEquipamento.value = true;
                    }
                })
                .catch(err => console.log(err));
        }

    }, []);

    useSignalEffect(() => {
        if (erroEquipamento?.value) {
            const alertConfig = {
                title: strings.erro,
                message: strings.noaExisteEquipamento,
                type: 'error',
                open: true,
                options: [
                    {
                        title: strings.ok,
                        onClick: () => {
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            }
                            cancelarEnvio();
                        }
                    }
                ],
                onClose: () => {
                    alerta.value = {
                        ...alerta.value,
                        open: false
                    }
                    cancelarEnvio();
                }
            };
            alerta.value = alertConfig;
        }
    });

    const cancelarEnvio = () => {
        if (handleCancelarEnvio) {
            handleCancelarEnvio();
        }
        resetFormulario();
    }

    const confirmarFecharModal = () => {
        const alertConfig = {
            title: strings.tituloModalCancelamento,
            message: strings.mensagemAlertaCancelamento,
            type: 'alert',
            open: true,
            options: [
                {
                    title: strings.sim,
                    onClick: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                        cancelarEnvio();
                    }
                },
                {
                    title: strings.nao,
                    onClick: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                    }
                }
            ],
            onClose: () => {
                alerta.value = {
                    ...alerta.value,
                    open: false
                }
            }
        };
        alerta.value = alertConfig;
    }

    const finalizarEnvioImagens = () => {
        if (callbackFinalizarEnvio) {
            callbackFinalizarEnvio();
        }
        resetFormulario();
    }

    /**
     * Gera o protocolo do exame
     *
     */
    const imprimirProtocolo = (idExame) => {
        Utils.imprimirProtocoloFn({ id: idExame }, () => {});
    }

    /**
     * Gera o termo de autorização
     * 
     * @param {number} paciente
     */
    const imprimirTermo = (pac) => {
        Utils.imprimirTcleFn({ id: pac }, () => {
            progresso.value = false;
            finalizarEnvioImagens();
        });
    }

    useSignalEffect(() => {
        if (gerarProtocolo.value && exameEnviado.value) {
            mensagemProgresso.value = strings.gerandoNumeroProtocolo;
            imprimirProtocolo(exameEnviado.value);
        }
    });

    useSignalEffect(() => {
        if (gerarTermo.value && paciente.value) {
            imprimirTermo(paciente.value,);
        }
    });

    const submitForm = (dados, setSubmitting) => {
        progresso.value = true;

        const { anexo, ...dadosGerais } = dados;

        const formData = new FormData();
        formData.append('dadosGerais', JSON.stringify(dadosGerais));
        formData.append('idSolicitacao', solicitacao.id);

        anexo.forEach((a, index) => {
            if (a && (a instanceof File)) {
                formData.append(`nome_anexos.${index}`, a.name);
                formData.append(`anexo.${index}`, a);
            }
        });

        axios.post(`${global.gConfig.url_base_espiro}/exame`, formData, { headers: { ...getHeaders(), 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                if (response.status === HttpStatus.CREATED) {
                    // Armazena o identificador do exame gerado
                    exameEnviado.value = response.data.data.exame;
                    paciente.value = response.data.data.paciente;
                    ufSolicitante.value = response.data.data.uf;
                    // Gera o protocolo
                    gerarProtocolo.value = true;
                    // Gera o TCLE
                    gerarTermo.value = true;
                } else {
                    gerarProtocolo.value = false;
                    gerarTermo.value = false;
                    progresso.value = false;
                    const alertConfig = {
                        title: strings.erro,
                        message: strings.mensagemErro,
                        type: 'error',
                        open: true,
                        options: [
                            {
                                title: strings.ok,
                                onClick: () => {
                                    alerta.value = {
                                        ...alerta.value,
                                        open: false
                                    }
                                }

                            }
                        ],
                        onClose: () => {
                            alerta.value = {
                                ...alerta.value,
                                open: false
                            }
                        }
                    };
                    alerta.value = alertConfig;
                }
            })
            .catch(err => {
                gerarProtocolo.value = false;
                gerarTermo.value = false;
                progresso.value = false;
                const { response } = err;
                let msg = strings.mensagemErroGeral;
                let titulo = strings.erro;
                let msgAlerta = '';

                if (response) {
                    if (response.status === HttpStatus.BAD_REQUEST) {
                        const dadosResp = response.data;
                        let arrMensagem = [];
                        dadosResp.errors.forEach(error => {
                            arrMensagem.push(`- ${error.message}`);
                        });
                        msg = arrMensagem.join('\n');
                        msgAlerta = msg;
                    } else {
                        msgAlerta = msg;
                    }
                } else {
                    msgAlerta = msg;
                }

                const alertConfig = {
                    title: titulo,
                    message: msgAlerta,
                    type: 'error',
                    open: true,
                    options: [
                        {
                            title: strings.ok,
                            onClick: () => {
                                alerta.value = {
                                    ...alerta.value,
                                    open: false
                                }
                            }

                        }
                    ],
                    onClose: () => {
                        alerta.value = {
                            ...alerta.value,
                            open: false
                        }
                    }
                };
                alerta.value = alertConfig;
            })
            .finally(() => {
                setSubmitting(false);
            });
    }

    return (
        <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={schemaInformacoesGerais}
            onSubmit={(data, { setSubmitting }) => {
                submitForm(data, setSubmitting);
            }}
        >
            {
                ({ handleSubmit }) => {
                    return (
                        <form noValidate onSubmit={handleSubmit}>
                            {
                                <ModalDadosGerais
                                    open={open}
                                    steps={steps}
                                    stepAtual={stepAtual}
                                    callbackFinalizado={handleSubmit}
                                    confirmarFecharModal={confirmarFecharModal}
                                    solicitacao={solicitacao}
                                    equipamentos={equipamentos}
                                />
                            }

                            <SttLoading
                                open={progresso.value}
                                text={mensagemProgresso.value}
                            />
                        </form>
                    )
                }
            }
        </Formik>
    );
}

export default EnvioImagens;