import React, { useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { SttTabs } from '@stt-componentes/core';
import { MODALIDADE, PERFIL, PERMISSOES } from '../../common/Constants';
import { TIPO_ABA_EXAME, VINCULO_PERFIL } from '../../componentes/exame/constantes';
import { temPermissaoRede } from '../../secutity/rbac';
import Aba from '../../componentes/exame/aba';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import usuario from '../../signals/usuario';

const Exame = () => {
    useSignals();

    const abas = useSignal([]);
    const abaAtiva = useSignal('0');

    const navigate = useNavigate();
    const location = useLocation();

    const resetAba = () => {
        navigate('/exames');
    }

    useEffect(() => {
        let dadosAbas = [];
        if (usuario.value.perfisRBAC) {
            const perfisInteresse = usuario.value.perfisRBAC.filter(p => [
                PERFIL.ADMINISTRADOR,
                PERFIL.ADMINISTRADOR_ESTADUAL,
                PERFIL.MEDICO_LAUDADOR,
                PERFIL.MEDICO_SOLICITANTE,
                PERFIL.REGULADOR_EXAME,
                PERFIL.TECNICO,
                PERFIL.VISUALIZADOR,
                PERFIL.VISUALIZADOR_REDE
            ].includes(p.identificador));
            perfisInteresse.forEach(perfil => {
                // INSTITUIÇÃO
                if (perfil.vinculo === VINCULO_PERFIL.INSTITUICAO) {
                    perfil.instituicoes.forEach(instituicao => {
                        dadosAbas.push({
                            id: instituicao.id,
                            tipo: TIPO_ABA_EXAME.INSTITUICAO,
                            titulo: instituicao.nome,
                            conteudo: Aba,
                            handleClick: resetAba
                        });
                    });
                }

                // REDE
                if (perfil.vinculo === VINCULO_PERFIL.REDE) {
                    perfil.redes.forEach(rede => {
                        const redeJaAdd = dadosAbas.some(d => (d.id === rede.id && d.tipo === TIPO_ABA_EXAME.REDE));
                        if (!redeJaAdd && usuario.value.modalidadesRede?.some(modalidade => modalidade.sigla_modalidade === MODALIDADE.SIGLA && modalidade.id_rede_telemedicina === rede.id)) {
                            dadosAbas.push({
                                id: rede.id,
                                tipo: TIPO_ABA_EXAME.REDE,
                                titulo: rede.descricao,
                                conteudo: Aba,
                                laudador: temPermissaoRede(rede, PERMISSOES.LAUDAR_EXAME_FABRICA) ? 1 : 0,
                                handleClick: resetAba
                            });
                        }
                    });
                }

                // GLOBAL
                if (perfil.vinculo === VINCULO_PERFIL.GLOBAL ||
                    perfil.identificador === PERFIL.ADMINISTRADOR_ESTADUAL ||
                    perfil.identificador === PERFIL.MEDICO_SOLICITANTE) {
                    dadosAbas.push({
                        id: perfil.identificador,
                        tipo: TIPO_ABA_EXAME.PERFIL,
                        titulo: `Perfil ${perfil.descricao}`,
                        conteudo: Aba,
                        handleClick: resetAba
                    });
                }

            });
            abas.value = dadosAbas;
        }
    }, []);

    useEffect(() => {
        if (location.state?.aba) {
            abaAtiva.value = '' + location.state.aba;
        }
    }, [location]);

    return (
        <SttTabs abas={abas.value} abaAtiva={abaAtiva.value} />
    );

}

export default Exame;