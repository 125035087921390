import React, { useEffect } from 'react';
import { SttTabs } from '@stt-componentes/core';
import { TIPO_ABA_EXAME, VINCULO_PERFIL } from '../../componentes/exame/constantes';
import { MODALIDADE, PERMISSOES } from '../../common/Constants';
import { temPermissaoRede } from '../../secutity/rbac';
import AbaFabrica from '../../componentes/laudo/fabrica';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import usuario from '../../signals/usuario';

const Laudo = () => {
    useSignals();

    const abas = useSignal([]);

    useEffect(() => {
        if (usuario.value.perfisRBAC) {
            let dadosAbas = [];
            usuario.value.perfisRBAC.forEach(perfil => {
                if (perfil.vinculo === VINCULO_PERFIL.REDE) {
                    perfil.redes.forEach(rede => {
                        if (temPermissaoRede(rede, PERMISSOES.LAUDAR_EXAME_FABRICA)) {
                            const redeJaAdd = dadosAbas.some(d => (d.id === rede.id && d.tipo === TIPO_ABA_EXAME.REDE));
                            if (!redeJaAdd && usuario.value.modalidadesRede?.some(modalidade => modalidade.sigla_modalidade === MODALIDADE.SIGLA && modalidade.id_rede_telemedicina === rede.id)) {
                                dadosAbas.push({
                                    id: rede.id,
                                    tipo: TIPO_ABA_EXAME.REDE,
                                    titulo: rede.descricao,
                                    conteudo: AbaFabrica,
                                });
                            }
                        }
                    });
                }
            });

            abas.value = dadosAbas;
        }
    }, [])

    return (
        <SttTabs abas={abas.value} />
    );

}

export default Laudo;